import React from 'react';
import { makeStyles, ListSubheader } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '0.875rem',
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        fontWeight: 700
    }
}));

const FooterListSubheader = props => {
    const classes = useStyles();
    return (
        <ListSubheader align='left' className={classes.root}>{props.title}</ListSubheader>
    )
}

export default FooterListSubheader;