import React from "react";
import {
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Slider from "react-slick";
import lang from "../../styles/lang";
import Testimonial from "../../component/Testimonial/Testimonial";

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "10vh 10vw",
    [theme.breakpoints.down("sm")]: {
      padding: "5vh 5vw",
    },
  },
  heading: {
    marginBottom: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
}));

const Testimonials = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.heading}
        align={isSmall ? "left" : "center"}
        color="textPrimary"
        variant="h1"
      >
        {lang.testimonials.title}
      </Typography>
      <Slider {...settings}>
        {lang.testimonials.testimonialSet.map((testimonial) => (
          <Testimonial
            key={testimonial.key}
            image={testimonial.image}
            body={testimonial.body}
            author={testimonial.author}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
