import React from "react";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import lang from "../../styles/lang";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    padding: "10vh 10vw",
    [theme.breakpoints.down("md")]: {
      padding: "5vh 5vw",
    },
  },
  heading: {
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  subheading: {
    marginBottom: "4rem",
    fontSize: "1.4rem",

    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1.2rem",
      lineHeight: "2rem",
    },
  },
  featureHeading: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const OurFeatures = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Typography
        align={isSmall ? "left" : "center"}
        color="textPrimary"
        variant="h1"
        className={classes.heading}
      >
        {lang.ourFeatures.title}
      </Typography>
      <Typography
        className={classes.subheading}
        variant="h3"
        align={isSmall ? "left" : "center"}
        color="textSecondary"
      >
        {lang.ourFeatures.subtitle}
        
      </Typography>
      
      <Grid container spacing={isSmall ? 4 : 10}>
        {lang.ourFeatures.featureSet.map((feature) => (
          <Grid key={feature.title} item xs={12} sm={6} >
            <img
              src={require(`../../assets/our_features/${feature.icon}`).default}
              alt="Home"
              width="48px"
            />
            <Typography
              className={classes.featureHeading}
              color="textPrimary"
              align="left"
              variant="h4"
            >
              {feature.title}
            </Typography>
            <Typography color="textPrimary" align="left" variant="subtitle1">
              {feature.subtitle}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default OurFeatures;
