import React from 'react';
import { ListItem, Link } from '@material-ui/core';

const FooterListItem = props => {
    return (
        <ListItem className={props.className}>
            <Link color='textPrimary' target={props.target} href={props.link}>{props.title}</Link>
        </ListItem>
    )
}

export default FooterListItem;