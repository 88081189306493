import React from 'react';
import './App.scss';
import MasloAppBar from './component/AppBar/MasloAppBar';
import HomePage from './pages/Home/Home'
import Footer from './sections/Footer/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App(props) {
  return (
    <div className="App">
      <MasloAppBar />
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
