import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import background from "../../assets/stats/stats-background.svg";
import lang from "../../styles/lang";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: `url(${background})`,
    minHeight: 400,
    padding: "10vh 10vw",
    [theme.breakpoints.down("md")]: {
      padding: "5vh 5vw",
    },
    [theme.breakpoints.down("sm")]: {
      background: "none",
      backgroundColor: "#F4E1FF",
    },
    [theme.breakpoints.down("xs")]: {
      background: "none",
      backgroundColor: "#F4E1FF",
      padding: "0 16px",
    },
  },
  title: {
    fontSize: "4rem",
    marginBottom: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  subtitle: {
    fontSize: "1rem",
    textTransform: "uppercase",
    fontWeight: 600,
    margin: "auto",
  },
}));

const Stats = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      className={classes.root}
    >
      <Grid item xs={12}>
          <Typography
            style={{marginBottom: '1rem'}}
            align="center"
            color="textPrimary"
            variant="h1"
          >
            {lang.stats.title}
          </Typography>
          <Typography
            style={{fontSize: '1.75rem'}}
            align="center"
            color="textSecondary"
            variant="h1"
          >
            {lang.stats.subtitle}
          </Typography>
          </Grid>
    </Grid>
  );
};

export default Stats;
