export default {
  general: {
    app_name: "maslo",
    learnMore: "Learn More",
    resources: "Resources",
    location_search_input_label:
      "Enter an address, neighbourdhood, city or postal code",
  },
  appBar: {
    login: "Login",
    getStarted: "Get Started",
    contact: "Contact",
    linkSet: [
      {
        title: "Buy",
        link: "buy",
      },
      {
        title: "Sell",
        link: "sell",
      },
      {
        title: "Rent",
        link: "rent",
      },
      {
        title: "About",
        link: "about",
      },
    ],
  },
  showcase: {
    title: `Together, let's make your real estate dreams a reality.`,
    subtitle:
      "Buy and/or sell your home with confidence with me. With years of residential real estate experience in Toronto and the GTHA, I can help you smoothly navigate the buying and selling process. My end-to-end service is tailored to meet your unique needs and ensure you get the best deal possible, all while enjoying a hassle-free experience that will keep your excitement alive.",
    cta: {
      href: "mailto:ramneet@maslorealty.com",
      target: "_blank",
      title: "Work with me",
    },
    contactInfo: {
      name: "Ramneet Gadi",
      designation: "Broker",
      direct: "Direct: (647) 407-4234",
      office: "Office: (905) 454-1400",
    }
  },
  stats: {
    title: "I've Got Your Back!",
    subtitle: "Unrivalled Support. Expert Advice.",
  },
  ourFeatures: {
    title: "Meet your new real estate partner.",
    subtitle: "What does your home mean to you? To me, home is a place where you can truly be yourself. A place where you can create cherished memories and mark life’s most important milestones."
      + " Whether you’re a buyer, seller, or an investor, my job is to use my extensive personal and professional experience in Toronto and GTHA to get you the right deal, on the right home, for you",
    featureSet: [
      {
        icon: "realtor.png",
        title: "A True Local Area Expert",
        subtitle:
          "For more than twenty years, Toronto and the GTHA have been my home. I know every city and its best, most beautiful parts. I will guide you to the best of your preferred locales, helping you understand what to look out for.",
      },
      {
        icon: "paralegal.png",
        title: "A Licensed Paralegal Protecting You",
        subtitle:
          "Legal know-how is a must when it comes to buying or selling a new home. Being a licensed paralegal, I will ensure that the terms of any deal are legally sound, making sure to protect your interests.",
      },
      {
        icon: "risk_management.png",
        title: "Your Very Own Risk Manager",
        subtitle:
          "Like any investment, real estate comes with its own risks. With 10 years of experience as a risk management specialist, you will sleep easier knowing that I have your back and best interests at heart. ",
      },
      {
        icon: "tech_savvy.png",
        title: "Marketing Insights to Maximize Your Deal",
        subtitle:
          "An informed decision is one that you can feel most confident about. Leveraging my experience in IT, I apply data-driven insights to maximize the value of your property and ensure you get the best deal possible.",
      },
      // {
      //   icon: "notary_public.png",
      //   title: "Notary Public",
      //   subtitle:
      //     "I believe there is a home out there for everyone, and I don't stop until we find the perfect one for you. I want you to love where you live.",
      // },
      // {
      //   icon: "optimist.png",
      //   title: "Optimist",
      //   subtitle:
      //     "I believe there is a home out there for everyone, and I don't stop until we find the perfect one for you. I want you to love where you live.",
      // },
    ],
  },
  ourServices: {
    title: "What I offer",
    serviceSet: [
      {
        key: "buy",
        title: "Buy with us",
        subtitle:
          "Buying with maslo is easy with our realtor printing and typesetting industry. Lorem Ipsum is simply dummy",
        imageUrl: "http://ipsumimage.appspot.com/285x208",
      },
      {
        key: "sell",
        title: "Sell with us",
        subtitle:
          "Sell with confidence knowing you're getting the best value for your home using Priscilla, our pricing algorithm.",
        imageUrl: "http://ipsumimage.appspot.com/285x208",
      },
      {
        key: "rent",
        title: "Rent with us",
        subtitle:
          "Whether you're looking for a tenant or a rental for yourself, maslo's large network has you covered.",
        imageUrl: "http://ipsumimage.appspot.com/285x208",
      },
    ],
  },
  testimonials: {
    title: "What my clients are saying about me.",
    testimonialSet: [
      {
        key: "testimonial-1",
        image: "arnold-joanna.png",
        body: "We had the pleasure of condo shopping with Ramneet for our first home and she made the process fun and easy! We feel lucky to have her guide us through the process. She's the best!",
        author: "| Arnold & Joanna",
      },
      {
        key: "testimonial-2",
        image: "conrad-caroline.png",
        body: "Very professional yet personable. Still telling our friends about her. Would recommend for anyone",
        author: "| Conrad & Caroline",
      },
      {
        key: "testimonial-3",
        image: "moh-linda.png",
        body: "We are from France and recently settled in Toronto at the end of 2019. Through the recommendation of a friend, we got to know Ramneet to help us find a house around Toronto. She is an extremely competent person, available during the entire research and visit process, very patient and smiling. We were able to count on her to explain the terms of purchases, credit, the history of the goods that interested us, and she was always attentive. If you want to buy a property, I advise you to contact her without hesitation. Thanks again.",
        author: "| Moh & Linda",
      },
      {
        key: "testimonial-4",
        image: "rodrigue-linda.png",
        body: "Ramneet was instrumental in getting our first home. Buying a house is such a stressful time but Ramneet made sure to take most of the hassle off of our plates making sure we were focusing on getting all the amenities/features we wanted in our dream house. We learned so much in this process and will always be thankful for finding such an incredible and dedicated realtor.",
        author: "| Rodrigue & Linda",
      },
    ],
  },
  footer: [
    {
      title: "Contact Us",
      set: [
        {
          title: "Email: ramneet@maslorealty.com",
          link: "mailto:ramneet@maslorealty.com",
        },
        {
          title: "Direct: (647) 407-4234",
          link: "tel:647-407-4234",
        },
        {
          title: "Office: (905) 454-1400",
          link: "tel:905-454-1400",
        },
        {
          title: "80 Pertosa Dr #2, Brampton, ON L6X 5E9",
          link: "https://www.c21empirerealty.com/contact.php",
          target: "_blank",
        },
      ],
    },
  ],
  footerDisclaimers: {
    copyright: "© CENTURY 21 EMPIRE REALTY INC. Brokerage*  independently owned & operated",
    mlsDisclaimer: "The trademarks REALTOR®, REALTORS® and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA. The trademarks MLS®, Multiple Listing Service® and the associated logos are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA.",
  }
};
