import React from 'react';
import { makeStyles, Grid, Typography, List, IconButton, ButtonGroup, ListItem, Divider } from '@material-ui/core';
import lang from '../../styles/lang'
import logo from '../../assets/logos/logo192.png'
import FooterListSubheader from '../../component/FooterListSubheader/FooterListSubheader';
import FooterListItem from '../../component/FooterListItem/FooterListItem';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import BrokerageLogo from '../../assets/century21logo.png'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#B1BDC8',
        padding: '5vh 10vw',
        [theme.breakpoints.down('sm')]: {
            padding: '5vh 5vw'
        }
    },
    logo: {
        maxHeight: 48,
        marginBottom: '1rem'
    },
    subtitle: {
        marginBottom: '1rem'
    },
    buttonGroup: {
        marginLeft: -12
    }
}));

const Footer = () => {
    const classes = useStyles();
    const socials = [
        {
            icon: <InstagramIcon />,
            link: 'https://www.instagram.com/ramneetgadi/?hl=en'
        },
        {
            icon: <FacebookIcon />,
            link: 'https://www.facebook.com/pg/rgadirealestate'
        },
        {
            icon: <TwitterIcon />,
            link: 'https://twitter.com/ramneetgadi'
        }
    ]
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid container direction='column' item sm={12} md={6}>
                    <a align='left' href="/"><img src={logo} alt="maslo" className={classes.logo} /></a>
                    <Typography className={classes.subtitle} align='left' variant='subtitle1'>{lang.showcase.title}</Typography>
                    <ButtonGroup>
                        {socials.map(social =>
                            <IconButton href={social.link} target='_blank'>
                                {social.icon}
                            </IconButton>)}
                    </ButtonGroup>
                </Grid>
                <Grid container item sm={12} md={6}>
                    {lang.footer.map(header =>
                        <Grid key={header.title} item xs={12} sm={6}>
                            <List
                                subheader={
                                    <FooterListSubheader title={header.title} />
                                }>
                                {header.set.map(link => <FooterListItem key={link.link} target={link.target} link={link.link} title={link.title} />)}
                                <ListItem>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.c21empirerealty.com/index.php"><img src={BrokerageLogo} alt="CENTURY 21 EMPIRE REALTY INC. Brokerage" className={classes.logo} /></a>
                                </ListItem>
                            </List>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Divider />
            <Typography>{lang.footerDisclaimers.copyright}</Typography>
            <Typography color="textSecondary">{lang.footerDisclaimers.mlsDisclaimer}</Typography>
        </div>
    );
}

export default Footer;