import React from "react";
import { Grid, Typography, Button, useMediaQuery, Paper, Divider } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import lang from "../../styles/lang";
import home from "../../assets/home.png";
import brokerageLogo from "../../assets/century21logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "10vh 10vw",
    [theme.breakpoints.down("md")]: {
      margin: "5vh 5vw",
    },
  },
  contactInfo: {
    fontSize: "2rem",
  },
  showcaseImage: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactCard: {
    padding: "1rem",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginBottom: "2rem",
  },
  inputForm: {
    marginTop: "2rem",
    height: 48,
  },
  title: {
    fontSize: "3em",
    marginBottom: "2rem",
  },
  brokerageLogo: {
    maxWidth: "60%"
  },
  divider: {
    height: "4px",
    backgroundColor: "#232C36",
    marginTop: "1rem",
    marginBottom: "2rem",
  }
}));

const Showcase = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={isSmall ? 4 : 10}
        justify="center"
        alignItems="center"
        direction="row">
        <Grid item xs={12} md={6}>
          <Paper className={classes.contactCard} elevation={3}>
            <Typography variant="h1" color="textPrimary">{lang.showcase.contactInfo.name}</Typography>
            <Typography className={classes.contactInfo} variant="h1" color="textSecondary">{lang.showcase.contactInfo.designation}</Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.contactInfo} variant="h1">{lang.showcase.contactInfo.direct}</Typography>
            <Typography className={classes.contactInfo} style={{marginBottom: "4rem"}} variant="h1">{lang.showcase.contactInfo.office}</Typography>
            <img src={brokerageLogo} className={classes.brokerageLogo} />
          </Paper>

          <Typography
            className={classes.title}
            align="left"
            color="textPrimary"
            variant="h1"
          >
            {lang.showcase.title}
          </Typography>
          <Typography align="left" color="textPrimary" variant="h3">
            {lang.showcase.subtitle}
          </Typography>
          <Button
            className={classes.inputForm}
            variant="contained"
            color="primary"
            href={lang.showcase.cta.href}
            target={lang.showcase.cta.target}
          >
            {lang.showcase.cta.title}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={home} alt="Your Home" className={classes.showcaseImage} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Showcase;
