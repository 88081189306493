import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#fffff6',
            main: '#ffddc3',
            dark: '#cbab93',
        },
        secondary: {
            light: '#ffffff',
            main: '#f4e1ff',
            dark: '#c1afcc'
        },
        text: {
            primary: '#232C36',
            secondary: '#616C76'
        },
    },
    typography: {
        fontFamily: 'sofia-pro',
        h1: {
            fontSize: '3rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '2.6rem',
            fontWeight: 400
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 300,
            lineHeight: 1.5
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 700
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 700
        }
    },
})

export default theme;