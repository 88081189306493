import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/logos/logo192.png'
import BrokerageLogo from '../../assets/century21logo.png'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Grid, IconButton } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

import lang from '../../styles/lang';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'white',
    padding: '1rem'
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: 48,
  },
  logoContainer: {
    marginLeft: "5vw",
  },
  brokerageLogo: {
    maxHeight: 60
  },
  brokerageLogoContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  }
}));

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

const MasloAppBar = (props) => {
  const classes = useStyles();
  return (
    <ElevationScroll {...props}>
      <AppBar position='sticky' className={classes.appBar}>
        <Toolbar>
          <Grid alignItems='center' container>
            <Grid item xs={6} md={4} className={classes.logoContaier}>
              <a href="/"><img src={logo} alt="maslo" className={classes.logo} /></a>
            </Grid>
            <Grid align='center' className={classes.brokerageLogoContainer} item md={4}>
              <img src={BrokerageLogo} alt="CENTURY 21 EMPIRE REALTY INC. Brokerage" className={classes.brokerageLogo} />
            </Grid>
            <Grid item align='right' xs={6} md={4}>
              <IconButton href="tel:647-407-4234" color='textPrimary' disableElevation>
                <PhoneIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}

export default MasloAppBar;