import React from "react";
import Showcase from "../../sections/Showcase/Showcase";
import OurFeatures from "../../sections/Features/OurFeatures";
import Testimonials from "../../sections/Testimonials/Testimonials";
import Stats from "../../sections/Stats/Stats";

const Home = () => {
  return (
    <>
      <Showcase />
      <Stats />
      <OurFeatures />
      <Testimonials />
    </>
  );
};

export default Home;
