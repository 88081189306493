import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";

const useStyle = makeStyles((theme) => ({
  iconSize: {
    fontSize: "15rem",
    color: "#E1F4FE",
    transform: "rotate(180deg)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "5rem",
    },
  },
  image: {
    maxWidth: "100%",
  },
  imageGridItem: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  author: {
    fontWeight: 700,
    color: "#D85179",
  },
}));

const Testimonial = (props) => {
  const classes = useStyle();
  return (
    <Grid container spacing={2}>
      <Grid className={classes.imageGridItem} item md={6}>
        <img
          alt={props.author}
          className={classes.image}
          src={require(`../../assets/testimonials/${props.image}`).default}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormatQuoteRoundedIcon className={classes.iconSize} />
        <Typography variant="h3" align="left">
          {props.body}
        </Typography>
        <Typography
          className={classes.author}
          variant="button"
          display="block"
          color="textPrimary"
          align="right"
        >
          {props.author}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Testimonial;
